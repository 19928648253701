// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contato-contact-form-contact-form-js": () => import("./../src/pages/contato/contact-form/ContactForm.js" /* webpackChunkName: "component---src-pages-contato-contact-form-contact-form-js" */),
  "component---src-pages-contato-js": () => import("./../src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-diagnostico-form-form-js": () => import("./../src/pages/diagnostico/form/Form.js" /* webpackChunkName: "component---src-pages-diagnostico-form-form-js" */),
  "component---src-pages-diagnostico-gratuito-js": () => import("./../src/pages/diagnostico-gratuito.js" /* webpackChunkName: "component---src-pages-diagnostico-gratuito-js" */),
  "component---src-pages-diagnostico-js": () => import("./../src/pages/diagnostico.js" /* webpackChunkName: "component---src-pages-diagnostico-js" */),
  "component---src-pages-homepage-segments-segments-js": () => import("./../src/pages/homepage/segments/Segments.js" /* webpackChunkName: "component---src-pages-homepage-segments-segments-js" */),
  "component---src-pages-homepage-slider-home-slider-js": () => import("./../src/pages/homepage/slider/HomeSlider.js" /* webpackChunkName: "component---src-pages-homepage-slider-home-slider-js" */),
  "component---src-pages-homepage-solutions-solutions-js": () => import("./../src/pages/homepage/solutions/Solutions.js" /* webpackChunkName: "component---src-pages-homepage-solutions-solutions-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-parceiros-js": () => import("./../src/pages/parceiros.js" /* webpackChunkName: "component---src-pages-parceiros-js" */),
  "component---src-pages-parceiros-partners-partners-js": () => import("./../src/pages/parceiros/partners/Partners.js" /* webpackChunkName: "component---src-pages-parceiros-partners-partners-js" */),
  "component---src-pages-quem-somos-js": () => import("./../src/pages/quem-somos.js" /* webpackChunkName: "component---src-pages-quem-somos-js" */),
  "component---src-pages-solucoes-controle-de-acesso-js": () => import("./../src/pages/solucoes/controle-de-acesso.js" /* webpackChunkName: "component---src-pages-solucoes-controle-de-acesso-js" */),
  "component---src-pages-solucoes-controle-de-expedicao-js": () => import("./../src/pages/solucoes/controle-de-expedicao.js" /* webpackChunkName: "component---src-pages-solucoes-controle-de-expedicao-js" */),
  "component---src-pages-solucoes-controle-de-itens-retornaveis-js": () => import("./../src/pages/solucoes/controle-de-itens-retornaveis.js" /* webpackChunkName: "component---src-pages-solucoes-controle-de-itens-retornaveis-js" */),
  "component---src-pages-solucoes-controle-de-movimentacao-js": () => import("./../src/pages/solucoes/controle-de-movimentacao.js" /* webpackChunkName: "component---src-pages-solucoes-controle-de-movimentacao-js" */),
  "component---src-pages-solucoes-ferrovia-e-mineracao-js": () => import("./../src/pages/solucoes/ferrovia-e-mineracao.js" /* webpackChunkName: "component---src-pages-solucoes-ferrovia-e-mineracao-js" */),
  "component---src-pages-solucoes-garantia-de-autenticidade-js": () => import("./../src/pages/solucoes/garantia-de-autenticidade.js" /* webpackChunkName: "component---src-pages-solucoes-garantia-de-autenticidade-js" */),
  "component---src-pages-solucoes-gestao-total-de-pneus-js": () => import("./../src/pages/solucoes/gestao-total-de-pneus.js" /* webpackChunkName: "component---src-pages-solucoes-gestao-total-de-pneus-js" */),
  "component---src-pages-solucoes-inventario-em-tempo-real-js": () => import("./../src/pages/solucoes/inventario-em-tempo-real.js" /* webpackChunkName: "component---src-pages-solucoes-inventario-em-tempo-real-js" */),
  "component---src-pages-solucoes-js": () => import("./../src/pages/solucoes.js" /* webpackChunkName: "component---src-pages-solucoes-js" */),
  "component---src-pages-solucoes-lavanderia-industrial-js": () => import("./../src/pages/solucoes/lavanderia-industrial.js" /* webpackChunkName: "component---src-pages-solucoes-lavanderia-industrial-js" */),
  "component---src-pages-solucoes-logistica-gestao-de-frotas-js": () => import("./../src/pages/solucoes/logistica-gestao-de-frotas.js" /* webpackChunkName: "component---src-pages-solucoes-logistica-gestao-de-frotas-js" */),
  "component---src-pages-solucoes-rastreabilidade-js": () => import("./../src/pages/solucoes/rastreabilidade.js" /* webpackChunkName: "component---src-pages-solucoes-rastreabilidade-js" */),
  "component---src-pages-solucoes-smart-sensor-js": () => import("./../src/pages/solucoes/smart-sensor.js" /* webpackChunkName: "component---src-pages-solucoes-smart-sensor-js" */),
  "component---src-pages-solucoes-solution-list-solution-list-js": () => import("./../src/pages/solucoes/solution-list/SolutionList.js" /* webpackChunkName: "component---src-pages-solucoes-solution-list-solution-list-js" */)
}

